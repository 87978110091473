import { ApiUrl } from "./Shared";
import axiosObject from "../utils/InterceptorService";

export const addLead = async (leadRequestModel)=>{
   let res = await axiosObject.post(`${ApiUrl}Leads/register`,leadRequestModel).then(res=>res.data);
   return res;
}

export const getAllLeads =async()=>{
    let res = await axiosObject.get(`${ApiUrl}Leads/GetAllLeads-ByCompany`).then(res=>res.data)
    return res
}
export const deleteLead=async (leadId)=>{
    let res = await axiosObject.delete(`${ApiUrl}Leads/Delete-Lead/${leadId}`).then(res=>res.data)
    return res;
}
export const updateLead=async (lead)=>{
    const res = await axiosObject.post(`${ApiUrl}Leads/Update-Lead`,lead).then(res=>res.data)
    return res;
}
export const addLeadProcessStep=async (leadProcessStep)=>{
    const res = await axiosObject.post(`${ApiUrl}Leads/addLeadProcessStep`,leadProcessStep).then(res=>res.data)
    return res;
}
export const addFollowUpdate=async (followUpdate)=>{
     const res = await axiosObject.post(`${ApiUrl}Leads/addLeadFollowUpdate`,followUpdate).then(res=>res.data)
        return res;
}
export const addLeadComment=async(commentModel)=>{
    let res = axiosObject.post(`${ApiUrl}Leads/addLeadComment`,commentModel).then(res=>res.data)
    return res;
}
export const leadFollowUpHistory =async (leadId)=>{
        let res = await axiosObject.get(`${ApiUrl}Leads/showLeadFollowUpHistory/${leadId}`).then(res=>res.data)
        return res;
}
export const addManageLead =async(manageLead) =>{
    let res = await axiosObject.post(`${ApiUrl}Leads/AddManageLead`,manageLead).then(res =>res.data)
    return res;
}
export const todaysFollowUp=async (leadTodaysFollowUp)=>{
    let res=await axiosObject.post(`${ApiUrl}Leads/TodaysFollowUpDate`,leadTodaysFollowUp).then(res=> res.data) 
    return res;
}
export const addTimeSheet =async(timeSheetModel)=>{
    let res=await axiosObject.post(`${ApiUrl}Leads/addTimeSheet`,timeSheetModel).then(res=>res.data)
    return res;
}
export const timeSheetList=async()=>{
    let res=await axiosObject.get(`${ApiUrl}Leads/getTimeSheetList`).then(res=>res.data)
    return res;
}
export const updateTimeSheet =async(updateTimeSheet)=>{
    let res= await axiosObject.post(`${ApiUrl}Leads/updateTimeSheet`,updateTimeSheet).then(res =>res.data)
    return res;
}