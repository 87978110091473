import React from "react";
import "./ProfileCard.css"
import {
  MDBCol,
  MDBContainer,
  MDBRow,
  MDBCard,
  MDBCardText,
  MDBCardBody,
  MDBCardImage,
} from "mdb-react-ui-kit";
import BreadcrumbComponent from "./Breadcrumb";
 function ProfilePage() {
  const getUserData = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    return user || {};
  };
  const user = getUserData();

  const Props = {
    fullName: user.fullName || "",
    email: user.email || "",
    phoneNumber: user.phoneNumber || "",
    userRole:
    user.userRole === 1
      ? "Admin"
      : user.userRole === 2
      ? "CompanyAdmin"
      : user.userRole === 3
      ? "SalesExecutive"
      : user.userRole === 4
      ? "SalesManager"
      : "Unknown", 
  };


  return (
    <>

<MDBRow>
          <MDBCol>
          <BreadcrumbComponent labels={{module:Props.userRole,currentRoute:"profile"}}/>
          </MDBCol>
        </MDBRow>
    <section style={{ backgroundColor: "#eee" }}>
      <MDBContainer className="py-5">
        <MDBRow>
          <MDBCol lg="4">
            <MDBCard className="mb-4">
              <MDBCardBody className="text-center">
                <MDBCardImage
                  src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/ava3.webp"
                  alt="avatar"
                  className="rounded-circle"
                  style={{ width: "150px" }}
                  fluid
                />
                <p className="text-muted mb-1">{Props.userRole}</p>
                <p className="text-muted mb-4">Bengaluru</p>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
          <MDBCol lg="8">
            <MDBCard className="mb-4">
              <MDBCardBody>
                <MDBRow>
                  <MDBCol sm="3">
                    <MDBCardText>Full Name</MDBCardText>
                  </MDBCol>
                  <MDBCol sm="9">
                    <MDBCardText className="text-muted">
                      {Props.fullName}
                    </MDBCardText>
                  </MDBCol>
                </MDBRow>
                <hr />
                <MDBRow>
                  <MDBCol sm="3">
                    <MDBCardText>Email</MDBCardText>
                  </MDBCol>
                  <MDBCol sm="9">
                    <MDBCardText className="text-muted">
                      {Props.email}
                    </MDBCardText>
                  </MDBCol>
                </MDBRow>
                <hr />
                <MDBRow>
                  <MDBCol sm="3">
                    <MDBCardText>Phone</MDBCardText>
                  </MDBCol>
                  <MDBCol sm="9">
                    <MDBCardText className="text-muted">
                      {Props.phoneNumber}
                    </MDBCardText>
                  </MDBCol>
                </MDBRow>
                <hr />

                <hr />
                <MDBRow>
                  <MDBCol sm="3">
                    <MDBCardText>Address</MDBCardText>
                  </MDBCol>
                  <MDBCol sm="9">
                    <MDBCardText className="text-muted">
                      Kalyanagr,Bengaluru,karnataka
                    </MDBCardText>
                  </MDBCol>
                </MDBRow>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </section>
    </>
  );
}
export default ProfilePage;
